// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/media/svastara/Stranice/hostel-ljubas/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/media/svastara/Stranice/hostel-ljubas/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/media/svastara/Stranice/hostel-ljubas/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prices-js": () => import("/media/svastara/Stranice/hostel-ljubas/src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */)
}

